@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://unpkg.com/movement.css/movement.css');

:root {
    --background-color: rgb(5, 5, 5);
    --primary-text-color: rgb(236, 235, 240);
    --tagline-text-color: rgb(150, 150, 150);
    --button-background-color: rgb(5, 5, 5);
    --button-border-color: rgb(236, 235, 240);
    --button-text-color: rgb(236, 235, 240);
    --button-hover-background-color: rgb(236, 235, 240);
    --button-hover-text-color: rgb(5, 5, 5);
}

* {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

body {
  margin: 0;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1.primary {
    color: var(--primary-text-color);
    font-size: 50px;
}

h2.primary {
    color: var(--primary-text-color);
    font-size: 40px;
}

.tagline {
    color: var(--tagline-text-color);
}

header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#buttons {
    display: flex;
    flex-direction: row;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
}

.primary-button {
    flex-basis: calc(100% / 2);
    flex-grow: 1;
    gap: 5px;
}

button {
    outline: none;
    background: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    border-radius: 5px;
    color: var(--button-text-color);
    font-family: inherit;
    padding: 14px;
    margin: 10px 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgb(0, 0, 0);
}

button:hover {
    background: var(--button-hover-background-color);
    color: var(--button-hover-text-color);
    transition: .2s linear;
}

.main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

@media (max-width: 800px) {
    .main {
        width: 75%;
    }
    .footer {
        width: 60%;
    }
}
